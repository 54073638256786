<template>
  <div>
    <div class="service-provicer-operations">
      <div>
        <a-button
          v-if="$hasPermissions(['rcs:service_provider:create'])"
          type="primary"
          class="add-btn"
          @click="jumpToCreate()"
          >新增</a-button
        >
      </div>
      <div class="data-filter" v-if="filterOptions">
        <a-select v-if="filterSelected" :default-value="filterSelected.value" style="width: 120px">
          <a-icon slot="suffixIcon" type="smile" />
          <a-select-option v-for="provider in filterOptions" :key="provider.value">{{
            provider.label
          }}</a-select-option>
        </a-select>
        <a-input
          v-model="searchKeyword"
          class="search-keyword"
          clearable
          placeholder
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
      </div>
    </div>
    <a-table
      :pagination="false"
      :columns="this.$constRepository.serviceProvider.columns"
      :data-source="serviceProviderList"
      class="service-table"
      rowKey="serviceProviderId"
    >
      <span slot="customTitle"> <a-icon type="smile-o" />Name </span>
      <span slot="tags" slot-scope="tags">
        <a-tag
          v-for="tag in tags"
          :key="tag"
          :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
          >{{ tag.toUpperCase() }}</a-tag
        >
      </span>
      <span slot="action" slot-scope="scope">
        <a-button
          v-if="$hasPermissions(['rcs:service_provider:update'])"
          class="green-btn"
          ghost
          @click="jumptoEdit(scope)"
          >编辑</a-button
        >
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:i18n:list'])" class="green-btn" ghost @click="jumptoI18n(scope)"
          >国际化</a-button
        >
        <a-divider type="vertical" />
        <a-button v-if="$hasPermissions(['rcs:service_provider:delete'])" class="red-btn" ghost>
          <a-popconfirm okText="是" cancelText="否" title="是否删除服务商?" @confirm="() => onDelete(scope)">
            <a href="javascript:;">删除</a>
          </a-popconfirm>
        </a-button>
      </span>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceProviderList',
  components: {},
  activated() {
    this.loadedPageData();
  },
  data() {
    return {
      filterSelected: this.$constRepository.serviceProvider.FILTER_OPTION_PROVIDER_NAME,
      filterOptions: [this.$constRepository.serviceProvider.FILTER_OPTION_PROVIDER_NAME],
      serviceProviderList: undefined,
      searchKeyword: '',
      requestParams: {},
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    processSearchKeyword() {
      this.$set(this.requestParams, 'name', undefined);
      switch (this.filterSelected) {
        case this.$constRepository.serviceProvider.FILTER_OPTION_PROVIDER_NAME:
          this.$set(this.requestParams, 'name', this.searchKeyword);
          break;
        default:
          break;
      }
    },
    async doSearch() {
      this.pageInfo.page = 1;
      await this.loadedPageData();
    },
    async loadedPageData() {
      this.processSearchKeyword();
      await this.listServiceProvider(this.requestParams.name, this.pageInfo);
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listServiceProvider(this.requestParams.name, this.pageInfo);
      }
    },
    async listServiceProvider(providerName, queryPage) {
      const params = {
        name: providerName,
        page: queryPage.page - 1,
        size: queryPage.size,
      };
      const response = await this.$apiManager.serviceProvider.getServiceProviders(params);
      this.serviceProviderList = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    jumpToCreate() {
      this.$router.push({ name: 'serviceProviderCreator' });
    },
    jumptoEdit(info) {
      this.$router.push({ name: 'serviceProviderEdit', params: { serviceProvider: info } });
    },
    jumptoI18n(info) {
      this.$router.push({ name: 'serviceProviderI18n', params: { serviceProvider: info } });
    },
    async onDelete(provider) {
      await this.$apiManager.serviceProvider.deleteServiceProviders(provider.serviceProviderId);
      if (this.serviceProviderList.length === 1 && this.pageInfo.page !== 1) {
        this.pageInfo.page -= 1;
      }
      this.loadedPageData();
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
  },
};
</script>

<style lang="less" scoped>
.service-provicer-operations {
  display: flex;
  justify-content: space-between;
  .add-btn {
    // margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-options {
      width: 100px;
    }
    .search-keyword {
      width: 200px;
    }
    .search-btn {
      // margin: 10px 0px;
      margin-left: 3%;
    }
  }

}
.service-table {
    margin-top: 1%;
  }
.pagination {
  margin-top: 3%;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
</style>
